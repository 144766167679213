import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ServiceAgreement } from '../data/service.agreement';
import { PagedResponse } from '../data/paged.response';

@Injectable()
export class AgreementService {

  constructor(private httpClient: HttpClient) {
  }

  findById(id: string) {
    return this.httpClient.get<ServiceAgreement>(`${environment.api_url}/api/service-agreements/${id}`);
  }

  newAgreement(createPayload: any) {
    return this.httpClient.post<ServiceAgreement>(`${environment.api_url}/api/service-agreements`, createPayload);
  }

  updateAgreement(id: string, updatePayload: any) {
    return this.httpClient.put<ServiceAgreement>(`${environment.api_url}/api/service-agreements/${id}`, updatePayload);
  }

  findByResidentId(residentId: string) {
    return this.httpClient.get<PagedResponse<ServiceAgreement>>(`${environment.api_url}/api/service-agreements?residentId=${residentId}`);
  }
  findByResId(residentId: string) {
    return this.httpClient.get<PagedResponse<ServiceAgreement>>(`${environment.api_url}/api/service-agreements/${residentId}`);

  }

  findAll() {
    return this.httpClient.get<PagedResponse<ServiceAgreement>>(`${environment.api_url}/api/service-agreements`);
  }
  searchRoomConditionReport(id) {
    return this.httpClient.get<PagedResponse<ServiceAgreement>>(`${environment.api_url}/api/room-condition-reports?residentId=${id}`);
  }
  delete(id: string) {
    return this.httpClient.delete(`${environment.api_url}/api/service-agreements/${id}`);
  }

  deleteAggreementImage(rsaId, imageId) {
    return this.httpClient.delete(`${environment.api_url}/api/service-agreements/${rsaId}/condition-report-files/${imageId}`);
  }
}
@Injectable()
export class ConditionService {
  constructor(private httpClient: HttpClient) {
  }

  findall(residentId: string) {
    return this.httpClient.get(`${environment.api_url}/api/room-condition-reports?residentId=${residentId}`);
  }

  newCondition(createPayloads: any) {
    return this.httpClient.post(`${environment.api_url}/api/room-condition-reports`, createPayloads);
  }
  newConditionWithID(id: any, createPayload: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(`${environment.api_url}/api/room-condition-reports/${id}/items`, createPayload, { headers });
  }
  updateCondition(id: string, itemid: number, updatepayload: any) {
    return this.httpClient.put(`${environment.api_url}/api/room-condition-reports/${id}/items/${itemid}`, updatepayload);
  }

  findbyIdCondition(id: string) {

    return this.httpClient.get(`${environment.api_url}/api/room-condition-reports/${id}`);
  }
  deleteCondition(id: string, itemid: number) {
    return this.httpClient.delete(`${environment.api_url}/api/room-condition-reports/${id}/items/${itemid}`);

  }
}
