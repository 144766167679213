import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbTokenStorage } from '@nebular/auth';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { AppFile } from '../data/app.file';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {


  constructor(private httpClient: HttpClient, private tokenLocalStorage: NbTokenStorage, private toastrService: ToastrService) { }

  getFile(id: any) {

    return this.httpClient.get(`${environment.api_url}/api/files/${id}/serve?X-TenantID=${environment.tenant_id}`);

  }

  upload(purpose: string, formData: FormData) {
    console.log(formData);
    
    if (!purpose) {
      this.toastrService.error('Purpose code is not specified.');
      return;
    }
    return this.httpClient.post<AppFile>(`${environment.api_url}/api/files?purpose=${purpose}`, formData, {
      headers: {
        Authorization: 'Bearer ' + this.tokenLocalStorage.get().getValue(),
        'X-Tenantid': `${environment.tenant_id}`,
      },
      reportProgress: true,
      observe: 'events',
    });
  }
  
  deleteFile(id: string) {
    return this.httpClient.delete(`${environment.api_url}/api/files/${id}`);
  }

  resizeImage(file: File, maxWidth?: number, maxHeight?: number): Promise<File> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();
  
      reader.onload = (e: any) => {
        img.src = e.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          // Use image's original dimensions if maxWidth or maxHeight are not provided
          const scale = Math.min(
            maxWidth ? maxWidth / img.width : 1,
            maxHeight ? maxHeight / img.height : 1
          );
  
          // Calculate new dimensions
          const width = img.width * scale;
          const height = img.height * scale;
  
          // Set canvas dimensions
          canvas.width = width;
          canvas.height = height;
  
          // Draw the image on the canvas
          ctx.drawImage(img, 0, 0, width, height);
  
          // Convert canvas back to a Blob and resolve it as a File
          canvas.toBlob(
            (blob) => {
              if (blob) {
                const resizedFile = new File([blob], file.name, {
                  type: file.type,
                  lastModified: Date.now(),
                });
                resolve(resizedFile);
              } else {
                reject(new Error('Failed to resize image'));
              }
            },
            file.type,
            1 // "1" indicates maximum quality
          );
        };
      };
  
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file); // Read the image file as data URL
    });
  }
  

}
