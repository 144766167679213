import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SubscriptionService } from './subscription.service';

@Injectable()
export class SubscriptionAuthGuard implements CanActivate {
    constructor(private subscriptionService: SubscriptionService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree> | boolean | UrlTree {
        // Remove the token from local storage
        return this.subscriptionService.subscriptionStatus().pipe(
            map((res:any) => {
                if (res.status === 'active') {
                    this.router.navigate(['/auth/login']);
                    return false;
                } else {
                    return true;
                }
            }),
            catchError(error => {
                this.router.navigate(['/auth/login']);
                return of(false);
            })
        );
    }
}
